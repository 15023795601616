/* styling used by mozilla example tutorial.  Impacts input forms in parts.js and scene.js.

https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/React_todo_list_beginning
 */


.app_container {
  padding: 20px 30px;
}

.container {
  padding: 0.8rem 1rem 0.7rem;
}

.has-footbar {
  padding-bottom:140px;
}
@media screen and (max-width:600px) {
  .has-footbar {
    padding-bottom:110px;
  }
  .MuiSnackbar-root {
    position:static !important;  
  }
}

.action_advance {
  float: right
}

.btn {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}

.btn__inline {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}


.btn__primary {
  color: #fff;
  background-color: #1873b9;
  border: 0;
  font-size: 1.4rem;
  padding: 0.8rem;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  border-radius: 5px;
}


.btn-group {
  display: flex;
  justify-content: space-between;
}
.btn-group > * {
  flex: 1 1 49%;
}
.btn-group > * + * {
  margin-left: 0.8rem;
}

/*.label__lg {
  line-height: 1.01567;
  font-weight: 200;
  padding: 0.4rem;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  text-align: center;
}
.input__lg {
  padding: 2rem;
  border: 2px solid #000;
  outline: none;
}
.input__lg:focus {
  border-color: #000;
  box-shadow: inset 0 0 0 2px;
  box-shadow: 0 0 5px rgba(180, 180, 200, 1);
}*/


input[type="file"] {
    display: none;
}
.image-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    width:100%;
}
.mirco-file-upload {
    cursor: pointer;
}
.image_wrapper_label {
   width: 100%;
   float: right;
}


/* .input__cd {
  padding: 0.3rem;
  border: 0px solid #000;
  resize: none;
  max-height: 100px;
  outline: none;
}
.input__cd:focus {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(150, 150, 200, 1);
} */

/*.title_cd {
  width: 80%;
  font-size: 1.5rem;
}

.description_cd {
  width: 100%;
  display: inline-block;
  font-size: 0.9rem;
  border: 1px dashed #eee;
}*/

.suggestion {
  text-align: center;
  font-size: 0.9rem;
    padding: 0.6rem;

}

.takes  .MuiGrid-container {
  margin-bottom:25px;
}

/*[class*="__lg"] {
  display: inline-block;
  width: 100%;
  font-size: 1.9rem;
}*/


/*@media screen and (min-width: 620px) {
  [class*="__lg"] {
    font-size: 2.4rem;
  }
}*/
.filters {
  width: 100%;
  margin: unset auto;
}


/* Todo item styles */
.todo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.todo > * {
  flex: 0 0 100%;
}
.todo-text {
  width: 100%;
  min-height: 4.4rem;
  padding: 0.4rem 0.8rem;
  border: 2px solid #565656;
}


