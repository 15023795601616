.filterContainer {
  max-width: 1775px;
  box-shadow: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 8px 0 !important;

  .ui.segments {
    border: none !important;
  }
}

.leftFilter {
  display: flex !important;
  @include Montserrat(16px, 600, 16px);
  align-items: center;
  padding: 0 !important;
}

.rightFilter { 
  border-left: none !important;
  padding: 0 !important;
}

.menuWrapper{
  .ui.menu.item {
    border-bottom: none !important;
    justify-content: flex-end;
    .link.item {
      margin-right: 10px;
      min-width: 50px
    }
  }
}