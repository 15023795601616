@import "./base/variables";
@import "./base/mixins";
@import "./base/typography";

@import "./layout/layout";
@import "./layout/filter";

@import "./components/cards";

.icon {
  color: $primary-light;
  transition: all .5s ease-in-out;
  &:hover {
    transition: all .5s ease-in-out;
    color: black;
  }
}
.toaster > div {
    margin-top: 2px;
}
.headbar .MuiIconButton-root {
  transition: all .5s ease-in-out;
  padding: 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.15);
    transition: all .5s ease-in-out;
  }
  &.account {
    margin-left: 10px;
  }
}
@media (max-width: 850px) {
  .headbar .MuiIconButton-root {
    padding: 7px;
  }
}