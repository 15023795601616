.cardWrapper {
  background: none !important;
  // width: 272px !important;
  // margin: 0.875em 1em !important;
  margin-bottom: 20px;
  position: relative;
  .select-item {
    position: absolute;
    right: 1px;
    z-index: 1;
    width: 16px;
    top: 1px;
    height: 16px;
    border-radius: 0;
    border: none;
  }
  .imgWrapper {
    position: relative;
  }
  &.full-width {
    .card {  
      width: 97%;
      margin-left: 1%;
    }
  }
  @media (max-width: 767.98px) { 
    margin: 16px 0 auto 0 !important;
    // width: 50% !important;

    &:nth-child(odd) {
      padding-right: 8px !important;
    }
    &:nth-child(even) {
      padding-left: 8px !important;
    }
  } 
}
.video-wrapper {
  position: relative;
  .video {
    position:absolute;
    top: 0;
    z-index: 2;
  }
  img {
    position: relative;
    z-index: 1;
  }
  &.video-play {
    .video {
      position:relative;
    }
    img {
      position:absolute;
      z-index:-1;
    }
  }
}

.contentWrapper {
  background: #fff;
  padding: 16px;
  border-radius: 0 !important;
  .header {
    @include Montserrat(12px, 600, 14px);
    color: #8F8F8F;
    border-bottom: 1px solid $w2;
    padding: 8px;
    display: flex;
    align-items: center;
    .label {
      color: $w5;
      margin-right: 8px;
      border-radius: 0px;
    }
  }
  .description {
    @include Montserrat(12px, 400, 14px);
    color: #8F8F8F;
    padding: 16px 0;
  }
}

.CardIconWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  .ui.label {
    background: $w1;
    @include Montserrat(10px, 500, 10px);
    color: $b2;
    border-bottom: 1px solid $w2;
    border-radius: 0;
  }
}

.btn-wrapper {
  position: relative;
  cursor: auto;
  .move-cursor {
    position: absolute;
    left: 20px;
    top: 5px;
    z-index: 9;
    cursor: pointer;
    img{
      width: 25px;
    }
  }
}

.container {
  touch-action: none;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  height: 100vh;
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.grid-item-content {
   box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 50%;
}

.ui button {
  touch-action: none !important;
}