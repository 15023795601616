body {
  background: $w1;
}

.processFooter {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  margin: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  font-weight: bold;
  .preview-btn {
    align-items: center;
    display: flex;
  }
  button {
    border-radius: 0 !important;
  }
  .info-container {
    border-left: none !important;
  }
  .submit-container {
    border-left: none !important;
  }
}